import React, {useState} from 'react';
import {Popover, Button, Input} from 'antd';

const PopOverAttentionBtn = props => {
  const [popVisible, setPopVisible] = useState (false);

  const content = () => (
    <div style={{maxWidth:350,overflowWrap:'break-word'}}>
        <div>
        {props.data}
        </div>
        <div style={{display:'flex',justifyContent:'flex-end'}}>
      <Button
        onClick={() => {
          setPopVisible (false);
        }}
        style={{marginTop: 10}}
        danger
      >
        Cancel
      </Button>
      </div>
    </div>
  );

  return (
    <Popover
      placement="left"
      content={() => content ()}
      title="Notes"
      trigger="click"
      visible={popVisible}
      onVisibleChange={() => setPopVisible (!popVisible)}
    >
       <div
        onClick={() => setPopVisible (!popVisible)}
        className="overFlowText"
      >
        {props.data}
      </div>
     <span style={{color:'#1890ff',cursor:'pointer'}}> See More</span>
      <style>
          {`
          .overFlowText{
            cursor:pointer;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          `}
      </style>
    </Popover>
  );
};

export default PopOverAttentionBtn;
